.weare {
    min-height: 100vh;
    margin-top: 80px;
    background-repeat: no-repeat;
    background-size: auto 50vh;
    background-position: -20vw center;

    @media (max-width: 687px) {
        background-position: center center;
    }
    
    .container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    &__header {
        width: 80%;
        display: flex;
        justify-content: center;
    }

    &__body {
        width: 80%;
        // margin: auto;
        color: #fff;
        font-size: 14px;
        font-family: 'regular';
        line-height: 23px;
        border: 1px solid #3b3b3b;
        background-color: rgb(42 42 42 / 23%);
        border-radius: 10px;
        padding: 16px 20px;

        @media (max-width: 687px) {
            width: 100%;
        }
    }
}