* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'regular';
    src: url(./assets/fonts/NotoSansGeorgian-Regular.ttf);
}

@font-face {
    font-family: 'caps';
    src: url(./assets/fonts/helvetica-caps-roman.ttf);
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}  

body {
    background-color: #fff;
    overflow-x: hidden;
}

.App {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgb(62,62,62);
        background: radial-gradient(circle, rgba(62,62,62,1) 0%, rgba(0,0,0,1) 75%);
        z-index: -1;
    }
}

section .container {
    padding-left: 70px;
    padding-right: 70px;

    @media (max-width: 1024px) {
        padding-left: 35px;
        padding-right: 35px;
    }
}

.copy {
    color: #fff;
    font-family: 'regular';
    font-size: 11px;
    opacity: .7;
}