.auction {
    width: 100%;
    border: 1px solid #3b3b3b;
    background-color: #2a2a2a;
    border-radius: 10px;
    padding: 30px 20px;
    margin-bottom: 100px;

    &__body {
        margin: 0 15px;

        li {
            margin: 7px 0;
            color: #fff;
            font-size: 16px;
            font-family: 'regular';
            line-height: 24px;
        }
    }
}