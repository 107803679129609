.pictograms {
    width: 100%;
    padding-top: 100px;
    // height: 100%;

    &__list {
        width: 100%;
        position: relative;
        float: left;
    }

    &__item {
        margin-bottom: 15px;
        border: 1px solid #3b3b3b;
        background-color: #2a2a2a;
        border-radius: 10px;
        padding: 12px;
        position: relative;
        float: left;
        width: 100%;

        &--icon {
            width: 25px;
            border-radius: 50%;
            margin-right: 15px;
            overflow: hidden;
            float: left;

            img {
                width: 100%;
            }
        }

        &--title {
            color: #fff;
            font-family: 'caps';
            font-size: 16px;
        }
    }
}