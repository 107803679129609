.panel {
    padding-top: 100px;
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    color: #fff;

    &__item {
        margin-bottom: 20px;
        border: 1px solid #3b3b3b;
        background-color: #2a2a2a;

        &--head {
            padding: 11px 12px;
            font-size: 14px;
        }

        .input {
            
            &--out {
                margin-bottom: 20px;
                
                p {
                    font-size: 13px;
                }
            }

        }
    }
}