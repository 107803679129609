.contact-btn {
    width: max-content;
    background-color: #d10808;
    border-radius: 10px;
    padding: 10px 16px;
    color: #fff;
    font-size: 13px;
    font-family: 'caps';
    text-decoration: none;
    display: flex;
    align-items: center;

    img {
        width: 13px;
        margin-right: 9px;
    }
}