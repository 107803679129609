.slider {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .swiper-pagination-bullet-active {
        background: #d10808;
    }

    &--in {
        width: calc(100vw - 50vw);
        height: 100vh;
        position: relative;

        @media (max-width: 1024px) {
            width: calc(100% - 200px);
            height: calc(100vh - 80px);
            position: absolute;
            top: 80px;
            right: 0;
        }

        @media (max-width: 668px) {
            width: calc(100% - 35px);
        }
    }

    .swiper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 0;
    }

    &__cover {
        width: 100%;
        height: 100vh;

        &--img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__text {
        position: absolute;
        z-index: 1;
        font-family: 'caps';
        color: #fff;

        &--title {
            font-size: 26px;
        }
        &--desc {
            font-size: 28px;
            margin-bottom: 40px;
        }
    }

    &__socnetwork {
        position: fixed;
        top: 150px;
        z-index: 1;
        left: 50px;

        @media (max-width: 1024px) {
            left: 10px;
        }

        &--link {
            margin-bottom: 80px;
            color: #fff;
            font-size: 14px;
            font-family: 'caps';
            text-decoration: none;

            svg {
                width: 20px;
                fill: #fff;

                @media (max-width: 1024px) {
                    width: 15px;
                }
            }

            &:hover {
                path {
                    fill: #d10808;
                }
            }
        }
    }
}