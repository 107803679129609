.join-section{
    width: 100%;
    min-height: 100vh;
    padding-top: 80px;

    &__title {
        position: relative;
        text-align: center;

        a {
            position: relative;
            color: rgb(255 255 255 / 30%);
            font-family: 'caps';
            font-weight: 700;
            font-size: 5vw;
            line-height: 8vw;
            text-decoration: none;

            @media (max-width: 1024px) {
                font-size: 8vw;
            }

            svg {
                width: 30px;
                position: absolute;
                right: 0;
                top: 78%;
                z-index: -1;
                fill: #fff;
                animation: mouseScale 1s ease-in-out infinite alternate;
            }

            @keyframes mouseScale {
                0% {
                    transform: scale(1.0);
                }
                100% {
                    transform: scale(1.7);
                }
            }
        }
    }

    &__body {
        width: 80%;
        margin: 50px auto;
        border: 1px solid #3b3b3b;
        background-color: #2a2a2a;
        border-radius: 10px;
        padding: 50px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-size: cover;
        background-position: center left;
        position: relative;

        &::before{
            content: "";
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#2a2a2a, .8);
        }

        p {
            color: #fff;
            line-height: 27px;
            font-size: 16px;
            font-family: 'regular';
        }

        @media (max-width: 1024px) {
            width: 100%;
        }
    }
}