.input {
    outline: none;
    padding: 7px 12px;
    border: 1px solid #3b3b3b;
    background-color: #fff;
    border-radius: 7px;
    color: #000;
    display: block;
    width: 100%;
    
    &--out {
        display: block;
        width: 100%;
        padding: 5px;
    }
}