.services {
    width: 100%;
    min-height: 100vh;
    padding-top: 80px;

    &__list {
        margin-left: -30px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border: 1px solid #3b3b3b;
        background-color: #2a2a2a;
        width: calc(50vw + 30px);
        height: 350px;
        padding: 50px;

        @media (max-width: 992px) {
            margin-left: 0;
            width: 100%;
            height: unset;
            border-radius: 10px;
            padding: 30px;
        }

        &--out {
            position: relative;
        }

        &--item {
            margin-left: 10px;
            color: #fff;
            font-size: 16px;
            line-height: 26px;
            font-family: 'caps';
        }
    }

    &__item {
        width: 100%;
        border: 1px solid #3b3b3b;
        background-color: #2a2a2a;
        border-radius: 10px;
        margin-bottom: 30px;
        overflow: hidden;

        &:hover {
            box-shadow: 0px 2px 21px 3px #3b3b3b;
        }

        &--text {
            background-color: rgb(31 31 31 / 70%);
            padding: 20px;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
        }

        &--title {
            color: #fff;
            font-size: 15px;
            font-family: 'caps';
        }

        &--desc {
            margin: 12px 0;
            color: #fff;
            font-size: 13px;
            font-family: 'regular';
        }
    }

    &__slider {
        width: 50vw;
        height: 100%;
        position: absolute;
        right: 0;

        @media (max-width: 992px) {
            width: 100%;
        }

        &--out {
            height: 350px;
            position: relative;
        }

        .swiper {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 0;
        }

        &--cover {
            width: 100%;
            height: 100%;
        }

        &--img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}