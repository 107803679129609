.header {
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    z-index: 2;
    position: absolute;
    display: flex;

    &__logo {
        width: 120px;
        height: 80px;
        position: relative;
        // top: 10px;
        left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        &--img {
            width: 80%;
        }
    }

    &__menu {
        display: flex;

        &--item {
            list-style: none;
        }

        &--link {
            display: block;
            margin-right: 26px;
            padding: 8px 0;
            color: #fff;
            font-size: 13px;
            font-family: 'caps';
            text-decoration: none;

            &.isactive {
                color: #d10808;
            }

            &:hover {
                color: #d10808;
            }
        }
    }

    &__lang {
        margin-left: 15px;
        position: relative;
        display: flex;
        justify-content: center;

        &--out {
            display: flex;
            flex: 1;
        }

        &--btn {
            width: 25px;
            cursor: pointer;

            img {
                width: 100%;
            }
        }

        &--list {
            position: absolute;
            top: 100%;
            padding:5px;
            border: 1px solid #3b3b3b;
            background-color: #2a2a2a;
            border-radius: 10px;
            color: #fff;
            font-family: 'caps';
            font-size: 13px;
            outline: none;
        }

        &--item {
            width: 25px;
            cursor: pointer;

            img {
                width: 100%;
            }
        }
    }

    .contact-btn {
        display: none;
    }

    &.fix {
        position: fixed;
        
        .contact-btn {
            display: flex !important;
        }
        
        .container {
            box-shadow: 0 -3px 33px -12px #fff;
            border-radius: 50px;
            background-color: #000;
        }
    }

    &.fix &{
        &__logo {
            position: absolute;
            display: none;
        }

        &__menu {
            &--link {
                margin-right: 40px;

                @media (max-width: 1200px) {
                    margin-right: 11px;
                }
            }
        }
    }
}