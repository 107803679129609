.footer {
    width: 100%;
    padding-bottom: 50px;

    &__logo {
        width: 100%;
        margin-bottom: 15px;

        &--img {
            width: 100%;
        }
    }

    &__copy {
        color: gray;
        font-size: 13px;
    }
}