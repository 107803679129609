.contact {
    padding: 30px 0 100px 0;

    &__logo {
        width: 150px;
        margin-bottom: 20px;
        
        @media (max-width: 1024px) {
            margin-top: 15px;
            width: 150px;
        }
    }

    &__list {
        width: 60%;

        @media (max-width: 768px) {
            width: 100%;
        }

        &--out {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        
        &--item {
            margin-bottom: 10px;
            color: #fff;
            font-size: 13px;
            font-family: 'caps';
            list-style: none;

            a {
                display: flex;
                align-items: center;
                margin-right: 40px;
                color: #fff;
                text-decoration: none;

                svg {
                    width: 16px;
                    margin-right: 10px;
                    fill: #fff;
                }

                &:hover {
                    color: #d10808;
                }
            }

            &:hover {
                color: #d10808;
            }
        }
    }

    &__socn {
        width: 40%;

        @media (max-width: 768px) {
            width: 100%;
        }

        &--item {
            width: 100%;
            display: block;
            margin-bottom: 10px;
            list-style: none;

            a {
                color: #fff;
                font-size: 14px;
                text-decoration: none;
                font-family: 'caps';
                display: flex;
                align-items: center;
            }

            svg {
                width: 16px;
                margin-right: 14px;
                fill: #fff
            }

            &:hover {
                svg {
                    fill: #d10808;
                }
            }
        }
    }

    &__form {
        margin-bottom: 50px;

        iframe {
            border: none;
            height: 100vw;
        }

        .input--out {
            padding: 0;
        }
        
        &--input {
            width: 100%;
            border: 1px solid #4e4e4e;
            padding: 8px 13px;
            outline: none;
            background-color: transparent;
            margin-bottom: 10px;
            color: #fff;
        }

        &--btn {
            width: 100%;
            max-height: 50px;
            height: 100%;
            display: block;
            background-color: #d10808;
            border-radius: 10px;
            padding: 10px 16px;
            color: #fff;
            font-size: 13px;
            text-align: center;
            font-family: 'caps';
            outline: none;
            cursor: pointer;
            border: none;
            margin-bottom: 10px;
        }
    }
}