.cars {
    min-height: 100vh;
    padding-top: 80px;

    .swiper-button-prev:after,
    .swiper-button-next:after {
        color: #d10808;
    }

    .swiper-pagination-bullet-active {
        background: #d10808;
    }

    .swiper-pagination {
        position: relative;
        margin-top: 10px;
    }
    
    &__container {
        background: #2a2a2a;
        border-radius: 20px;
        border: 1px solid #3b3b3b;
    }

    &__head {
        padding: 8px 12px;
        border-bottom: 1px solid #3b3b3b;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        .section-title {
            margin-bottom: 0;
        }

        &--btn {
            border: 1px solid #d10808;
            border-radius: 10px;
            padding: 10px 16px;
            color: #fff;
            font-size: 13px;
            font-family: "caps";
            text-decoration: none;
        }
    }

    &__item {
        width: 100%;
        display: block;
        margin-bottom: 45px;
        overflow: hidden;
        text-decoration: none;

        &--cover {
            width: 100%;
            height: 200px;
            overflow: hidden;
        }

        &--img {
            height: 100%;
        }

        &--title {
            margin: 10px 15px;
            color: #fff;
            font-size: 15px;
            font-family: 'caps';
            text-align: center;
        }
        &--price {
            margin: 0 15px;
            color: #fff;
            font-size: 13px;
            font-family: 'regular';
            text-align: center;
        }
    }

    &__page {
        padding-top: 80px;
    }

    &__detail {
        padding-top: 120px;
        color: #fff;

        &--head {
            margin-top: 30px;
        }

        &--title {
            font-size: 22px;
            font-family: 'caps';
        }

        &--price {
            margin-left: 40px;
            font-size: 20px;
            font-family: 'caps';
            color: #fff;
            opacity: .8;
        }
        
        &--images {
            width: 100%;
            height: calc(100vh / 1.7);
            border-radius: 10px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &--body {
            margin-top: 30px;
            color: #fff;
            font-size: 14px;
            font-family: "regular";
            line-height: 22px;
            border: 1px solid #3b3b3b;
            background-color: #2a2a2a;
            border-radius: 10px;
            padding: 13px 0;
        }

        &--body-text {
            padding: 0 15px;
        }

        &--table-item {
            padding: 6px 15px;
            list-style: none;
            border-left: 2px solid transparent;

            &:hover {
                background-color: rgba(#000, .3);
                border-left: 2px solid #d10808;
            }
        }

        &--table-title {
            font-size: 13px;
            font-family: 'caps';
        }

        &--table-value {
            font-size: 13px;
            font-family: 'regular';

            .true {
                svg {
                    fill: #00ff24;
                }
            }

            .false {
                svg {
                    fill: #ff0000;
                }
            }
        }
    }
}